@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.4.2/css/all.css';

.dev {
  --environment-indicator: darkred;
  --environment-indicator-text: white;
}

.qa {
  --environment-indicator: darkgoldenrod;
  --environment-indicator-text: white;
}

.demo {
  --environment-indicator: darkgreen;
  --environment-indicator-text: white;
}

.prod {
  --environment-indicator: #344557;
  --environment-indicator-text: white;
}

.light-theme
{
  /*Body*/
  --theme-background: #eeeeee;
  --theme-secondary-background: white;
  --theme-text: #333;
  --theme-secondary-text: #555;
  /*Navbar*/
  --theme-header: #2d3e50;
  --theme-nav-dropdown-text: black;
  /*Scrollbars*/
  --theme-scrollbar-track: #CCC;
  --theme-scrollbar-thumb: #777;
  /*Modals*/
  --theme-modal-text: #333;  
  /*Tables*/
  --theme-table-header: #2d3e50;
  --theme-table-header-text: white;
  --theme-table-text: #333;  
  --theme-table-bold-text: black;  
  --theme-table-odd-row: #bbb;
  --theme-table-even-row: #ddd;
  --theme-table-summary-row: #899fa3;
  --theme-table-summary-row-text: black;
  --theme-table-hover: #899fa3;
  --theme-table-hover-text: #4293A4;
  /*Buttons*/
  --theme-button: #6c757d;
  --theme-button-text: white;
  --theme-button-hover: #555;
  --theme-button-warning-hover: #dc3545;
  --theme-selector-hover: #083b88;
  /*Form Controls*/
  --theme-form-control: #212529;
  --theme-form-control-text: #adb5bd;
  --theme-form-control-border: #495057;
  --theme-form-control-disabled: #212529;
  --theme-form-control-highlight: #6ea8fe;  
  
}

.dark-theme
{
  /*Body*/
  --theme-background: black;
  --theme-secondary-background: #181818;
  --theme-text: #777;
  --theme-secondary-text: #777;
  /*Navbar*/
  --theme-header: #212529;
  --theme-nav-dropdown-text: #ffffff8c;   
  /*Scrollbars*/
  --theme-scrollbar-track: #333;
  --theme-scrollbar-thumb: #777;  
  /*Modals*/
  --theme-modal-text: #eeeeee;
  /*Tables*/  
  --theme-table-header: #212529;
  --theme-table-header-text: white;
  --theme-table-text: #aaa;    
  --theme-table-bold-text: white;    
  --theme-table-odd-row: #333;
  --theme-table-even-row: #212529;
  --theme-table-summary-row: #212529;
  --theme-table-summary-row-text: white;
  --theme-table-hover: #111;
  --theme-table-hover-text: #4293A4;
  /*Buttons*/
  --theme-button: #6c757d;
  --theme-button-text: white;
  --theme-button-hover: #555;
  --theme-button-warning-hover: #dc3545;
  --theme-selector-hover: #083b88;
  /*Form Controls*/
  --theme-form-control: #212529;
  --theme-form-control-text: #adb5bd;
  --theme-form-control-border: #495057;
  --theme-form-control-disabled: #212529;
  --theme-form-control-highlight: #6ea8fe;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*******************************************************************************/
/*                                    THEMES                                  */
/*******************************************************************************/
body
{
  background-color: var(--theme-background);
  color: var(--theme-text);
}
 
.flexlayout__tab
{
  background-color: var(--theme-background) !important;
}


/*******************************************************************************/
/*                                 Scroll Bars                                 */
/*******************************************************************************/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--theme-scrollbar-track);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--theme-scrollbar-thumb);
}

.always-show-scrollbar {
  overflow-x: scroll;
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--theme-scrollbar-thumb) var(--theme-scrollbar-track); /* For Firefox */
  -webkit-overflow-scrolling: touch;
}

/* Specifically target the horizontal scrollbar */
.always-show-scrollbar::-webkit-scrollbar-horizontal {
  height: 6px;
}

.always-show-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--theme-scrollbar-thumb);
  visibility: visible;
  min-width: 30px;
}

.always-show-scrollbar::-webkit-scrollbar-track:horizontal {
  background-color: var(--theme-scrollbar-track);
  visibility: visible;
}

/*******************************************************************************/
/*                                    GENERAL                                  */
/*******************************************************************************/
.div-window
{
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.div-window .div-header
{
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.div-window .div-main
{
  flex: 1 1 auto;
  overflow-y: auto;
}

.div-window .div-footer
{
  flex: 0 1 70px;
  min-height: 70px;
}

.clickable{
  cursor: pointer;
}

.loading-container
{
  display: flex;
  align-items: center; /* Vertically center the content inside .loading-container */
  justify-content: center; /* Horizontally center the content inside .loading-container */
  height: 100%; /* Optional: Adjust this based on your needs */
}

.flex-container
{
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.flexlayout__tab::-webkit-scrollbar {
  width: 12px;
}

.align-right{
  display: flex;
  justify-content: flex-end;
}

.no-border{
border: transparent;
}

.no-background{
  background: transparent;
}
 /*******************************************************************************/
/*                                    MODALS                                    */
/*******************************************************************************/
.modal-content
{
  color: var(--theme-modal-text);
}

/*******************************************************************************/
/*                                    HEADER/NAV                               */
/*******************************************************************************/
.environment-indicator-container {
  border-top: 2px solid var(--environment-indicator);
}

.environment-indicator-container .environment-indicator {
  background-color: var(--environment-indicator);
}

.environment-indicator-container {
  display: flex;
  width: 100%;
  z-index: 10;
  position: absolute;
}

.environment-indicator-container .environment-indicator {
  padding: 0 10px;
  color: var(--environment-indicator-text);
  font-weight: 600;
  text-transform: uppercase;
  top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: -3px auto;
  font-size: 8pt;
}

.navbar > .container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.navbar {
  margin: 0;
  padding: 0;
}

nav
{
  background-color: var(--theme-header);
  font-weight: 600;  
}

.dropend > .dropdown-toggle
{
  color: var(--theme-nav-dropdown-text) !important;
  font-weight: 400;
}

.navbar-brand img {
  height: 40px;
  /* margin: 10px 0 10px 15px; */
 }

 .nav-icon {
  font-size: 24pt;
 }

 .nav-menu-icon {
  font-size: 14pt;
 }

/*******************************************************************************/
/*                                    TICKER                                   */
/*******************************************************************************/
.ticker-container {
  position: fixed;
  bottom: 0;
  min-width: 100%;
 }

  /*fix bug in package that's making the ticker 50px tall by default*/
.styles_ticker__-0UvZ
{
  height:70px; 
  background-color: var(--theme-secondary-background)
}

/*fix bug in package that's making the ticker not scroll continuously*/
.styles_ticker__-0UvZ > div
{
  width: 50%;
}

/*widen ticker items to make room for longer names*/
.styles_tickerItemFinancial__m6Lt7
{
  width:250px !important;
}

.styles_tickerTopLevel__vRrjN
{
  color: var(--theme-text);
}

.styles_tickerItem__Vj57z:hover
{
  background-color: var(--theme-secondary-background)
}

/*******************************************************************************/
/*                                    TABS                                     */
/*******************************************************************************/
.nav-tabs {
  --bs-bg-opacity: 1;
  border: 0px;
  }

.nav-tabs .nav-item .nav-link {
  font-weight: 600;
   color:var(--theme-secondary-text)
}

.nav-tabs .nav-item .nav-link.active {
  color: var(--theme-table-hover-text);
  border: 0px;
}

.nav-tabs .nav-item .nav-link:hover {
  color: var(--theme-table-hover-text);
  border: 0px;
}

.tab-content {
  border-top: transparent;
  height: calc(100% - (41px)) !important;
}

.tab-content .tab-pane {
  height: 100%;
}

/*******************************************************************************/
/*                                    Tables                                   */
/*******************************************************************************/
/*////Base Table Styles////*/
table {
  white-space: nowrap;
  vertical-align: middle;
  width: 100%;
  font-weight: 400;
}

th {
  padding: 5px 5px 5px 5px;
}

td {
  padding: 5px 5px 5px 5px;
}

td.clickable:hover {
  font-weight: 600;
}

tr.clickable:hover > td {
  color: var(--theme-table-hover-text);
  font-weight: 600;
}

table.clickable tr:hover > td {
  color: var(--theme-table-hover-text);
  font-weight: 600;
}


td.clickable:hover {
  color: var(--theme-table-hover-text);
  background-color: var(--theme-table-hover);
}

table{
  color: var(--theme-table-text);
}

.table-bold {
  color: var(--theme-table-bold-text);
}

th {
  background-color: var(--theme-table-header);
  color: var(--theme-table-header-text);
}

tr:nth-child(odd) > td {
  background-color: var(--theme-table-odd-row);
}

tr:nth-child(even) > td {
  background-color: var(--theme-table-even-row);
}

.summary-row  td {
  font-weight: 600;
}

table .summary-row  td {
  background-color: var(--theme-table-summary-row);
  font-weight: 600;
  color: var(--theme-table-summary-row-text);
}

table .summary-row  td {
  background-color: var(--theme-table-summary-row);
  font-weight: 600;
  color: var(--theme-table-summary-row-text);
}

.centered {
  text-align: center;
}

.right-align {
  text-align: right;
}

.table-condensed th {
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-condensed td {
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-extra-small th {
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
}

.table-extra-small tr > td {
  padding: 2px;
  font-size: 12px;
  font-weight: 400;
}

.market-grid td {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 1px;
}

.market-grid tbody
{
  overflow: auto;
}

.market-grid tr:hover > td {
  color:unset;
}

.market-grid tr:nth-child(odd) > td {
  background-color: var(--theme-table-odd-row);
}

.market-grid tr:nth-child(even) > td {
  background-color: var(--theme-table-odd-row); /*This is correct; we don't want to alternate on the market grid*/
}

tr.row-collapse> td {
  background-color: var(--theme-table-even-row);
  border: none;
}

tr.row-collapse {
  visibility: collapse;
}

tbody:has(> tr .queue-toggle:checked)  tr.row-collapse {
  visibility: visible;
}


/*******************************************************************************/
/*                    Accordian Selector (Market Selector)                     */
/*******************************************************************************/
.AccordionBodyL1{
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.AccordionBodyL1 .accordion-button {
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 40px;
}

.AccordionBodyL1 .accordion-button[aria-expanded="true"] {
/*   color: #9ec5ff;
  background-color:#020c1b; */
}

.AccordionBodyL2{
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 20px;
}


.AccordionBodyL2 .list-group {
  padding-left: 30px;
}

.AccordionBodyL2 .list-group-item:hover {
   background-color: var(--theme-selector-hover);
   color: var(--theme-button-text);
}

/*******************************************************************************/
/*                                     Tiles                                   */
/*******************************************************************************/
.tile
{
  border-style: solid;
  border-width: medium;
  border-radius: 10px;
}

.tile
{
  background-color: var(--theme-secondary-background);
  border-color: var(--theme-background);
}


/*******************************************************************************/
/*                            Market Data Dashboard                            */
/*******************************************************************************/

.generate-marks-button {
}

.market-attribute-selector:hover {
  background-color:  var(--theme-selector-hover);;
}

.market-attribute-selector {
  text-align: center;
}

.market-data-graph
{
  height: 300px;
}

.trades-table
{
  margin-top:10px;
}

.marks-table
{
  margin-top: 10px;
}

.mark-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mark-cell.submitted {
  color: #b88603;
}

/*******************************************************************************/
/*                                    Pagination                               */
/*******************************************************************************/
.pagination
{
    background-color: var(--theme-background);
}


.pagination > li > button
{
    background-color: var(--theme-secondary-background);
    border-color: var(--theme-secondary-background);
    color: var(--theme-text);
}


pagination > li > button:focus,
.pagination > li > button:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
  color: var(--theme-table-bold-text);
  font-weight: 600;
  background-color: var(--theme-table-hover);
  border-color: var(--theme-table-hover);
}

.pagination > .active > button
.pagination > .active > button:hover
{
  color: var(--theme-table-bold-text);
  font-weight: 600;
  background-color: var(--theme-table-odd-row);
  border: solid 1px var(--theme-table-odd-row);
}

/*******************************************************************************/
/*                                    FORMS                                    */
/*******************************************************************************/
.form-footnote {
  font-size: 12px;
  font-weight: 600;
  float: right;
}

.form-footnote {
  color: var(--theme-table-text)
}

.form-text-area {
    width: 100%;
    height: 50vh;
    resize: auto; 
    font-size: 16px; 
    padding: 10px;
    box-sizing: border-box;
    overflow: auto;
}

/*******************************************************************************/
/*                                    DUAL-LISTBOX                             */
/*    This is a 3rd party control, so have to emulate the bootstrap styling    */
/*******************************************************************************/
.react-dual-listbox
{
  width: 100%  !important;
  padding: 0.375rem !important;
}

.rdl-control 
{
    display: block !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background-clip: padding-box !important;    
    appearance: none !important;
    border-radius: 0.375rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.dark-theme .rdl-control
{
  color: var(--theme-form-control-text) !important;
  background-color: var(--theme-form-control) !important;
  border: 1px solid var(--theme-form-control-border) !important;
}

.rdl-control:disabled
{
    font-weight: 400 !important;
}

.dark-theme .rdl-control:disabled
{
  color: var(--theme-form-control-text) !important;
  background-color: var(--theme-form-control-disabled) !important;
}

.rdl-filter
{
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  appearance: none !important;
  border-radius: 0.375rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.dark-theme .rdl-filter
{
  color: var(--theme-form-control-text) !important;
  background-color: var(--theme-form-control) !important;
  border: 1px solid var(--theme-form-control-border) !important;
}

.dark-theme .rdl-filter:disabled
{
    color: var(--theme-form-control-text) !important;
    background-color: var(--theme-form-control-disabled) !important;
}


.rdl-control > option
{
  font-size: 1rem !important;
  font-weight: normal !important;
  display: block !important;
  min-height: 1.2em !important;
  padding: 0px 2px 1px !important;
}

.rdl-control:disabled > option
{
  font-weight: 400 !important;
}

.dark-theme .rdl-control:disabled > option
{
  color: var(--theme-form-control-text) !important;
}

.rdl-control:focus
{
  border: 1px solid var(--theme-form-control-highlight) !important;
}

.rdl-filter:focus
{
  border: 1px solid var(--theme-form-control-highlight) !important;
}


.rdl-control > option:checked
{
  background-color: var(--theme-form-control-border) !important;
  color: var(--theme-form-control-text) !important;
}


.rdl-control:focus option:checked
{
  background: linear-gradient(0deg, var(--theme-form-control-highlight) 0%, var(--theme-form-control-highlight) 100%) !important;
  color: black !important; /*this just won't take...*/
}

.rdl-btn
{
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: 1px solid transparent!important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  color: var(--theme-button-text) !important;
  background-color: var(--theme-button) !important;
  border-color: var(--theme-button) !important;
}

.rdl-hide-move-all .rdl-move-all-left
{
  display: none !important;
}

.rdl-hide-move-all .rdl-move-all-right
{
  display: none !important;
}

.rdl-btn:hover
{
  background-color: var(--theme-button-hover) !important;
  border-color: var(--theme-button-hover) !important;
}

/*******************************************************************************/
/*                                    Multi-Select                             */
/*******************************************************************************/
.neutral-react-select-container .neutral-react-select__control {
  background-clip: padding-box !important;
}

.dark-theme .neutral-react-select-container .neutral-react-select__control {
  background-color: var(--theme-form-control) !important;
  border: 1px solid  var(--theme-form-control-border) !important;
}

.dark-theme .neutral-react-select-container .neutral-react-select__control--is-focused {
  border: 1px solid var(--theme-form-control-highlight) !important;
}

.neutral-react-select-container .neutral-react-select__menu {
  background-clip: padding-box !important;
}

.dark-theme .neutral-react-select-container .neutral-react-select__menu {
  background-color: var(--theme-form-control);
  background-clip: padding-box !important;
  border: 1px solid var(--theme-form-control-border) !important;  
}

.dark-theme .neutral-react-select-container .neutral-react-select__option {
  color: var(--theme-form-control-text) !important;  
}

.dark-theme .neutral-react-select-container .neutral-react-select__option--is-selected {
  background-color:var(--theme-form-control-border) !important; 
}

.dark-theme .neutral-react-select-container .neutral-react-select__option--is-focused {
  background-color: var(--theme-form-control-border) !important;
  color: var(--theme-form-control-text) !important;
}

.dark-theme .neutral-react-select-container .neutral-react-select__option--is-selected {
  background-color: var(--theme-form-control-highlight) !important; 
  color: var(--theme-form-control) !important;
}

.neutral-react-select-container .neutral-react-select__indicator-separator {
}

.neutral-react-select-container .neutral-react-select__input-container,
.neutral-react-select-container .neutral-react-select__placeholder,
.neutral-react-select-container .neutral-react-select__single-value {
}



/*******************************************************************************/
/*                                    TOASTS                                   */
/*******************************************************************************/
.Toastify__toast-container {
  padding-top: 0px !important;
}

.Toastify__progress-bar-theme--dark
{
  height: 1px !important;
  background-color: var(--theme-form-control-highlight) !important;
}

.Toastify__progress-bar-theme--light
{
  height: 1px !important;
  background-color: var(--theme-form-control-highlight) !important;
}

.Toastify__toast-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.Toastify__toast-body {
  color:  var(--theme-secondary-text) !important;
}

.Toastify__toast-theme--dark
{
  border: 1px solid transparent!important;
  border-radius: 10px !important;
  background-color: var(--theme-background) !important;
}

.Toastify__toast-theme--light
{
  border: 1px solid transparent!important;
  border-radius: 10px !important;
  background-color: var(--theme-background) !important;
}

.Toastify__toast-theme--dark
{
  border-color:var(--theme-form-control-highlight) !important;
}

.Toastify__toast-theme--light
{
  border-color:var(--theme-form-control-highlight) !important;
}

.Toastify__toast-theme--dark.Toastify__toast--error
{
  border-color: #EB5757 !important;
}

.Toastify__toast-theme--light.Toastify__toast--error
{
  border-color: #EB5757 !important;
}

.Toastify__progress-bar--error {
  background-color: #EB5757  !important;
}

.Toastify__toast-theme--dark.Toastify__toast--warning
{
  border-color: orange  !important;
}

.Toastify__toast-theme--light.Toastify__toast--warning
{
  border-color: orange  !important;
}

.Toastify__progress-bar--warning {
  background-color: orange  !important;
}

.Toastify__toast-theme--dark.Toastify__toast--success
{
  border-color:green !important;
}

.Toastify__toast-theme--light.Toastify__toast--success
{
    border-color:green !important;
}

.Toastify__progress-bar--success {
  background-color: green !important;
}


/*******************************************************************************/
/*                                    Buttons                                  */
/*******************************************************************************/
.btn-delete {
  background-color: var(--theme-button); /* Secondary color */
  color: var(--theme-button-text);
  transition: background-color 0.3s ease;
}

.btn-delete:hover {
  background-color: var(--theme-button-warning-hover); /* Danger color on hover */
}

